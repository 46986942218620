import { useShow, IResourceComponentsProps, useOne } from "@pankod/refine-core";

import { Show, Typography, MarkdownField } from "@pankod/refine-antd";

import { ISKU, IEmployee } from "interfaces";

const { Title, Text } = Typography;

export const SKUShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<ISKU>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { data: employeeData, isLoading: employeeIsLoading } =
        useOne<IEmployee>({
            resource: "employee",
            id: record?.sku_idea?.[0] || "",
            queryOptions: {
                enabled: !!record,
            },
        });

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>Id</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>SKU</Title>
            <Text>{record?.sku_name}</Text>

            <Title level={5}>Idea</Title>
            <Text>
                {employeeIsLoading ? "Loading..." : employeeData?.data.employee_name}
            </Text>

            <Title level={5}>Content</Title>
            <MarkdownField value={record?.sku_name} />
        </Show>
    );
};
