import { IResourceComponentsProps, useMany } from "@pankod/refine-core";

import {
    List,
    Table,
    TextField,
    useTable,
    Space,
    EditButton,
    ShowButton,
    DeleteButton,
} from "@pankod/refine-antd";

import { ISKU, IEmployee } from "interfaces";

export const SKUList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps } = useTable<ISKU>();

    const IdeaIds = tableProps?.dataSource?.flatMap((p) => p.sku_idea);
    const { data, isLoading } = useMany<IEmployee>({
        resource: "employee",
        ids: IdeaIds || [],
        queryOptions: {
            enabled: IdeaIds !== undefined,
        },
    });

    return (
        <List>
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="id" title="ID" />
                <Table.Column dataIndex="sku_name" title="SKU" />
                <Table.Column dataIndex="sku_status" title="Status" />
                <Table.Column<ISKU>
                    dataIndex={"employee"}
                    title="Idea"
                    render={(_, record) => {
                        if (isLoading) {
                            return <TextField value="Loading..." />;
                        }

                        return (
                            <TextField
                                value={data?.data
                                    .filter((item) =>
                                        record.sku_idea?.includes(item.id),
                                    )
                                    .map((p) => p.employee_name)
                                    .join(", ")}
                            />
                        );
                    }}
                />
                <Table.Column<ISKU>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
