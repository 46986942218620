import { Refine, AuthProvider } from "@pankod/refine-core";
import {
    notificationProvider,
    Layout,
    ReadyPage,
    ErrorComponent,
} from "@pankod/refine-antd";
import dataProvider from "@pankod/refine-airtable";
import routerProvider from "@pankod/refine-react-router-v6";

import { useAuth0 } from "@auth0/auth0-react";

import "@pankod/refine-antd/dist/reset.css";

import { Login } from "pages/login";

import axios from "axios";

import { SKUList, SKUCreate, SKUEdit, SKUShow } from "pages/sku";
import { EmployeeList, EmployeeCreate, EmployeeEdit } from "pages/employee";

const API_TOKEN = "key2icHWbCqOf97z7";
const BASE_ID = "apptp0Yo0ZxExaHcZ";

const App: React.FC = () => {
    const { isLoading, isAuthenticated, user, logout, getIdTokenClaims } =
        useAuth0();

    if (isLoading) {
        return <span>loading...</span>;
    }

    const authProvider: AuthProvider = {
        login: () => {
            return Promise.resolve();
        },
        logout: () => {
            logout({ logoutParams: { returnTo: window.location.origin }});
            return Promise.resolve("/");
        },
        checkError: () => Promise.resolve(),
        checkAuth: async () => {
            try {
                const token = await getIdTokenClaims();
                if (token) {
                    axios.defaults.headers.common = {
                        Authorization: `Bearer ${token.__raw}`,
                    };
                    return Promise.resolve();
                } else {
                    return Promise.reject();
                }
            } catch (error) {
                return Promise.reject();
            }
        },
        getPermissions: () => Promise.resolve(),
        getUserIdentity: async () => {
            if (user) {
                return Promise.resolve({
                    ...user,
                    avatar: user.picture,
                });
            }
        },
    };

    getIdTokenClaims().then((token) => {
        if (token) {
            axios.defaults.headers.common = {
                Authorization: `Bearer ${token.__raw}`,
            };
        }
    });

    return (
        <Refine
            LoginPage={Login}
            dataProvider={dataProvider(API_TOKEN, BASE_ID)}
            authProvider={authProvider}
            ReadyPage={ReadyPage}
            routerProvider={routerProvider}
            resources={[
                {
                    name: "SKU",
                    list: SKUList,
                    create: SKUCreate,
                    edit: SKUEdit,
                    show: SKUShow,
                },
                {
                    name: "employee",
                    list: EmployeeList,
                    create: EmployeeCreate,
                    edit: EmployeeEdit,
                    canDelete: true,
                },
            ]}
            notificationProvider={notificationProvider}
            Layout={Layout}
            catchAll={<ErrorComponent />}
        />
    );
};

export default App;
